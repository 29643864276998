var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-layout", { attrs: { column: "", "justify-center": "" } }, [
    _c(
      "div",
      {
        staticClass: "social-player",
        style: _vm.starsPlaying ? { zIndex: 99 } : {}
      },
      [
        _c(
          "v-flex",
          { ref: "root", staticClass: "buzz-player-container" },
          [
            _c("StarExplosion", {
              ref: "stars",
              staticClass: "star-explosion",
              attrs: {
                radius: _vm.radius,
                nOfStar1: 4,
                nOfStar2: 8,
                maxStar1Size: 10,
                maxStar2Size: 14,
                speed: 0.5,
                shape: _vm.shape
              }
            }),
            _c(
              "RatioContainer",
              {
                staticClass: "ratio-container",
                on: {
                  dblclick: function($event) {
                    return _vm.selectUser(_vm.userID)
                  }
                }
              },
              [
                _c("div", { staticClass: "outline-border" }, [
                  _c(
                    "div",
                    { staticClass: "inline-border" },
                    [
                      _c("UserMedia", {
                        staticClass: "user-media",
                        attrs: { identity: _vm.userID, imageUrl: _vm.imageURL }
                      }),
                      _c(
                        "v-layout",
                        {
                          staticClass: "buzz-player-bottom-info",
                          attrs: { row: "" }
                        },
                        [
                          _c(
                            "v-layout",
                            {
                              staticClass: "buzz-player-name",
                              attrs: { column: "", "justify-center": "" }
                            },
                            [
                              _c(
                                "v-flex",
                                [
                                  _c("UserName", {
                                    attrs: {
                                      firstname: _vm.firstname,
                                      lastname: _vm.lastname
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.currentMission.behavior == "Buzz In"
                            ? _c(
                                "v-layout",
                                {
                                  staticClass: "buzz-player-counter",
                                  class: {
                                    active: _vm.isActive,
                                    buzzed1st: _vm.buzzCount == 1,
                                    buzzed: _vm.buzzCount > 1
                                  },
                                  attrs: { column: "", "justify-center": "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.buzzIn()
                                    }
                                  }
                                },
                                [
                                  _c("v-flex", [
                                    _vm.isActive
                                      ? _c("img", {
                                          staticClass: "buzz-in-icon",
                                          attrs: {
                                            src: require("../../../../assets/icon_buzz_lines_left_white.svg")
                                          }
                                        })
                                      : _vm.buzzCount > 0
                                      ? _c("img", {
                                          staticClass: "buzz-in-icon",
                                          attrs: {
                                            src: require("../../../../assets/icon_buzz_lines_left_yellow.svg")
                                          }
                                        })
                                      : _vm._e(),
                                    _c("span", [
                                      _vm._v(" " + _vm._s(_vm.btnText) + " ")
                                    ]),
                                    _vm.isActive
                                      ? _c("img", {
                                          staticClass: "buzz-in-icon",
                                          attrs: {
                                            src: require("../../../../assets/icon_buzz_lines_right_white.svg")
                                          }
                                        })
                                      : _vm.buzzCount > 0
                                      ? _c("img", {
                                          staticClass: "buzz-in-icon",
                                          attrs: {
                                            src: require("../../../../assets/icon_buzz_lines_right_yellow.svg")
                                          }
                                        })
                                      : _vm._e()
                                  ])
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("div", { staticClass: "buzz-player-team" }, [
                        _c(
                          "div",
                          { staticClass: "buzz-player-team-bg" },
                          [
                            _c(
                              "v-icon",
                              { staticClass: "buzz-player-team-icon" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.teamIcon || "radio_button_unchecked"
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ])
              ]
            ),
            _c("div", { ref: "circle3", staticClass: "winner-outline" })
          ],
          1
        ),
        _c(
          "v-flex",
          { staticClass: "buzz-controls", attrs: { "d-flex": "" } },
          [
            _c(
              "v-layout",
              { attrs: { row: "", "justify-center": "" } },
              [
                _c(
                  "v-flex",
                  {
                    staticClass: "buzz-player-score",
                    staticStyle: { "min-width": "60px" },
                    attrs: {
                      shrink: "",
                      "d-flex": "",
                      "justify-center": "",
                      "align-center": ""
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.num) + "pts ")]
                ),
                !_vm.readOnly
                  ? _c(
                      "v-flex",
                      {
                        staticStyle: { "margin-bottom": "-3px" },
                        attrs: { shrink: "", "d-flex": "" }
                      },
                      [
                        _c("StarRating", {
                          ref: "star_rating",
                          attrs: {
                            increment: 0.5,
                            padding: 1,
                            rating: _vm.rating,
                            "star-size": 16,
                            "show-rating": false,
                            "border-width": 0,
                            "inactive-color": _vm.$theme.get(
                              "--disabled-color"
                            ),
                            "active-color": "#efe73c",
                            "border-color": "transparent"
                          },
                          on: {
                            "rating-selected": function($event) {
                              return _vm.onStarred($event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }