<template>
  <v-layout column justify-center>
    <div class="social-player" :style="starsPlaying ? { zIndex: 99 } : {}">
      <v-flex class="buzz-player-container" ref="root">
        <StarExplosion
          :radius="radius"
          :nOfStar1="4"
          :nOfStar2="8"
          :maxStar1Size="10"
          :maxStar2Size="14"
          :speed="0.5"
          :shape="shape"
          ref="stars"
          class="star-explosion"
        />
        <RatioContainer class="ratio-container" @dblclick="selectUser(userID)">
          <div class="outline-border">
            <div class="inline-border">
              <UserMedia
                :identity="userID"
                :imageUrl="imageURL"
                class="user-media"
              />
              <v-layout row class="buzz-player-bottom-info">
                <v-layout column justify-center class="buzz-player-name">
                  <v-flex>
                    <UserName :firstname="firstname" :lastname="lastname" />
                  </v-flex>
                </v-layout>
                <v-layout
                  v-if="currentMission.behavior == 'Buzz In'"
                  column
                  justify-center
                  class="buzz-player-counter"
                  :class="{
                    active: isActive,
                    buzzed1st: buzzCount == 1,
                    buzzed: buzzCount > 1
                  }"
                  @click="buzzIn()"
                >
                  <v-flex>
                    <img
                      v-if="isActive"
                      class="buzz-in-icon"
                      :src="
                        require('../../../../assets/icon_buzz_lines_left_white.svg')
                      "
                    />
                    <img
                      v-else-if="buzzCount > 0"
                      class="buzz-in-icon"
                      :src="
                        require('../../../../assets/icon_buzz_lines_left_yellow.svg')
                      "
                    />
                    <span> {{ btnText }} </span>
                    <img
                      v-if="isActive"
                      class="buzz-in-icon"
                      :src="
                        require('../../../../assets/icon_buzz_lines_right_white.svg')
                      "
                    />
                    <img
                      v-else-if="buzzCount > 0"
                      class="buzz-in-icon"
                      :src="
                        require('../../../../assets/icon_buzz_lines_right_yellow.svg')
                      "
                    />
                  </v-flex>
                </v-layout>
              </v-layout>

              <div class="buzz-player-team">
                <div class="buzz-player-team-bg">
                  <v-icon class="buzz-player-team-icon">
                    {{ teamIcon || "radio_button_unchecked" }}
                  </v-icon>
                </div>
              </div>
            </div>
          </div>
        </RatioContainer>
        <div class="winner-outline" ref="circle3"></div>
      </v-flex>
      <v-flex d-flex class="buzz-controls">
        <v-layout row justify-center>
          <v-flex
            shrink
            d-flex
            justify-center
            align-center
            style="min-width: 60px"
            class="buzz-player-score"
          >
            {{ num }}pts
          </v-flex>
          <v-flex v-if="!readOnly" shrink d-flex style="margin-bottom: -3px">
            <StarRating
              ref="star_rating"
              :increment="0.5"
              :padding="1"
              :rating="rating"
              :star-size="16"
              :show-rating="false"
              :border-width="0"
              :inactive-color="$theme.get('--disabled-color')"
              active-color="#efe73c"
              :border-color="'transparent'"
              @rating-selected="onStarred($event)"
            />
          </v-flex>
        </v-layout>
      </v-flex>
    </div>
  </v-layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { Power3, Power2, TweenMax } from "gsap/TweenMax"
import UserMedia from "@/components/GroupTeams/Common/User/UserMedia"
import RatioContainer from "@/components/GroupTeams/Common/RatioContaier"
import StarExplosion from "@/components/GroupTeams/Common/StarExplosion"
import UserName from "@/components/GroupTeams/Common/User/UserName"
import StarRating from "vue-star-rating"

export default {
  name: "SocialPlayer",
  components: {
    RatioContainer,
    UserMedia,
    StarRating,
    UserName,
    StarExplosion
  },
  props: {
    imageURL: String,
    src: MediaStream,
    score: Number,
    readOnly: {
      type: Boolean,
      default: false
    },
    allowKeys: {
      type: Boolean,
      default: false
    },
    firstname: String,
    lastname: String,
    teamIcon: String,
    buzzCount: Number,
    userID: String,
    isWinner: Boolean
  },
  data() {
    return {
      isMediaReady: false,
      num: 0,
      rating: 0,
      radius: 0,
      shape: "square",
      starsPlaying: false
    }
  },
  mounted() {
    // must be wrapped into $nextTick because of a Vue
    // transition parent tag
    this.$nextTick(() => {
      // we need to get the initial star animation size
      // after the intro animation is already played
      setTimeout(() => {
        if (!this.$refs.root) return
        this.radius = (this.$refs.root.clientWidth / 2) * 1.33
      }, 1000)
      setTimeout(() => {
        this.animateScore(0, this.score)
      }, 500)
    })
  },
  created() {
    window.addEventListener("keyup", this.onKeyUp)
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.onKeyUp)
  },
  watch: {
    isWinner(value) {
      if (value) this.playWinningAnimation()
    },
    score(newValue, oldValue) {
      if (!isNaN(newValue)) this.animateScore(oldValue, newValue)
    }
  },
  methods: {
    ...mapActions("soundeffect", ["updateGameSoundEffect"]),
    ...mapActions(["addBuzz"]),
    setRating(num) {
      this.rating = num
      this.onStarred(num)
    },
    selectUser(userID) {
      if (this.isHost) {
        const user = this.users[userID]
        const selected = !user.selected
        this.$store.dispatch("updateUser", { userID, obj: { selected } })
      }
    },
    onKeyUp({ code }) {
      if (!this.allowKeys) return null
      switch (code) {
        case "KeyA":
          return this.setRating(1)
        case "KeyS":
          return this.setRating(2)
        case "KeyD":
          return this.setRating(3)
        case "KeyF":
          return this.setRating(4)
        case "KeyG":
          return this.setRating(5)
        default:
          return null
      }
    },
    animateScore(from, to) {
      const obj = { num: from }
      TweenMax.to(obj, 1, {
        num: to,
        ease: Power3.easeOut,
        roundProps: "num",
        onUpdate: this.setScore,
        onUpdateParams: [obj]
      })
    },
    setScore({ num }) {
      this.num = num
    },
    onStarred(num) {
      this.$emit("onStarred", num)
    },
    playWinningAnimation() {
      console.log("Playing winning animation in social")
      if (!this.$refs.root) {
        console.log("The root node is undefined")
        return
      }
      this.starsPlaying = true
      // define animation size with the respect to the
      // current size and shape
      this.radius = (this.$refs.root.clientWidth / 2) * 1.33

      // turn off the star animation here
      this.$nextTick(() => {
        if (this.$refs.stars) this.$refs.stars.animate()
      })

      TweenMax.fromTo(
        this.$refs.circle3,
        0.25,
        { opacity: 0 },
        { opacity: 1, overwrite: false }
      )

      TweenMax.fromTo(
        this.$refs.circle3,
        1,
        { scale: 1 },
        { scale: 1.33, overwrite: false, ease: Power2.easeInOut }
      ).delay(0.15)

      TweenMax.to(this.$refs.circle3, 0.3, {
        opacity: 0,
        overwrite: false
      }).delay(0.75)

      setTimeout(() => {
        this.starsPlaying = false
      }, 1500)
    },
    async buzzIn() {
      if (!this.isCurrentUser) return
      try {
        await this.addBuzz({
          missionID: this.currentMissionID,
          userID: this.userID
        })
      } catch (e) {
        return console.error(e.message)
      }
      this.updateGameSoundEffect({ key: "BUZZ_IN", status: "play" })
    }
  },
  computed: {
    ...mapGetters("GameUsers", ["users"]),
    ...mapGetters(["styles"]),
    ...mapGetters("auth", ["isHost", "user"]),
    isCurrentUser() {
      return this.userID === this.user.id
    },
    currentMission() {
      return this.$store.getters.getCurrentMission
    },
    currentMissionID() {
      return this.$store.getters.currentMission
    },
    buzz() {
      return this.$store.getters.buzz
    },
    isWaiting() {
      return (this.buzzCount === 0) & !this.isCurrentUser
    },
    isActive() {
      return (this.buzzCount === 0) & this.isCurrentUser
    },
    btnText() {
      if (this.isWaiting) return "Waiting..."
      else if (this.isActive) return "BUZZ IN"
      else if (this.buzzCount === 1) return this.buzzCount + "ST"
      else if (this.buzzCount === 2) return this.buzzCount + "ND"
      else if (this.buzzCount === 3) return this.buzzCount + "RD"
      else return this.buzzCount + "TH"
    }
  }
}
</script>

<style lang="scss">
.social-player {
  position: relative;
  .buzz-player-team {
    position: absolute;
    top: -1px;
    right: -1px;
    color: $color-white;
    font-size: 1.2rem;
  }
  .buzz-player-team-bg {
    background-color: $color-white;
    border-radius: 0 0 0 8px;
    width: 41px;
    height: 41px;
    padding-left: 2px;
    padding-bottom: 2px;
    text-align: center;
  }
  .buzz-player-team-icon {
    font-size: 30px;
    line-height: 40px;
    color: $color-black;
  }
  .buzz-player-bottom-info {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 26px;
    padding-top: 3px;
    padding-left: 3px;
    padding-right: 3px;
    background-color: $color-white;
  }
  .buzz-player-counter {
    user-select: none;
    background-color: $color-disabled;
    width: 50%;
    color: $color-white;
    font-weight: bold;
    font-size: 0.8em;
    border-radius: 6px;
    border: 1px solid $color-disabled;
    pointer-events: none;
    cursor: not-allowed;
    & > span {
      vertical-align: middle;
    }
    .buzz-in-icon {
      display: inline-block;
      vertical-align: middle;
      width: 8px;
      height: auto;
      padding-bottom: 2px;
    }
  }
  .buzz-player-counter.active {
    pointer-events: all;
    cursor: pointer;
    border: 1px solid $color-primary-dark;
    background-color: $primary_accent_color;
    box-shadow: inset 0px -1px 1px rgba($color-black, 0.5),
      inset 0px 1px 1px rgba($color-white, 0.5);
  }
  .buzz-player-counter.buzzed {
    box-shadow: none;
    background-color: $color-black;
    border: 1px solid $color-black;
  }
  .buzz-player-counter.buzzed1st {
    box-shadow: none;
    background-color: $color-green;
    border: 1px solid $color-green;
  }
  .buzz-player-name {
    width: 50%;
    font-size: 0.9em;
    color: $color-primary-dark;
    & > div {
      align-self: flex-start;
    }
  }
  .buzz-player-score {
    color: $color-white;
    font-size: 1.2em;
    white-space: nowrap;
    transition: color 0.3s;
  }
  .buzz-player-score.got-it-right {
    animation-duration: 1.25s;
    animation-delay: 0.1s;
    animation-name: got-it-right;
  }
  @keyframes got-it-right {
    0% {
      color: $color-white;
    }
    50% {
      color: $secondary_accent_color;
    }
    100% {
      color: $color-white;
    }
  }
  .buzz-controls {
    user-select: none;
    height: 30px;
    margin-bottom: -30px;
  }
  .buzz-player-container {
    position: relative;
  }
  .ratio-container {
    overflow: visible;
  }
  .outline-border {
    @extend .rtb-border, .rtb-box-shadow;
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 9px;
    overflow: hidden;
  }
  .inline-border {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: 3px solid $color-white;
    background-color: $color-white;
    border-radius: 7px;
    overflow: hidden;
    position: relative;
  }
  .star-explosion {
    pointer-events: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: transparent;
  }
  .winner-outline {
    top: 0;
    left: 0;
    pointer-events: none;
    opacity: 0;
    z-index: 99;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 7px;
    border: 6px solid $secondary_accent_color;
  }
  // .vue-star-rating {
  //   margin: auto;
  // }
}

// TODO(Andrew): refactor
.buzz-player-container {
  .user-media {
    // TODO(Andrew): use SASS variable
    padding-bottom: 26px;
  }
}
</style>
